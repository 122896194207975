var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"annuaire__view"},[_c('div',{staticClass:"header"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher un annuaire","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{on:{"click":function($event){return _vm.$router.push({path: '/annuaire/add'})}}},[_vm._v(" Ajouter un annuaire ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.annuaires,"search":_vm.search,"footer-props":{
  itemsPerPageOptions: [10,20,30]
}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.getBaseUrl(item.url),"target":"_blank"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatedDate(item.updated))+" ")]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.renderingText(item.content))+" ")]}},{key:"item.website",fn:function(ref){
var item = ref.item;
return [(item.website != null)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Modifier les infos de l'annuaire"},on:{"click":function($event){return _vm.$router.push({path: '/annuaire/' + item.id})}}},[_vm._v(" mdi-pencil ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }